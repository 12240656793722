import * as React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageDailog from '../components/Dailog/ImageDailog';
import Server from '../api/index';
import CustomSnackbar from '../components/CustomSnackbar';

interface ImageItemProps {
    employeeDocsData: any;
    onDeleteImage: (obj: any) => void;
    isShown: boolean;
}
const ImageItem: React.FC<ImageItemProps> = ({ employeeDocsData, onDeleteImage, isShown }) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarType, setSnackbarType] = React.useState<'success' | 'error'>('success');

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const showSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarType(type);
        setSnackbarOpen(true);
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteImage = async (obj: any) => {
        if (!obj.id) return; // Ensure fileId is available

        // const formData = new FormData();
        // formData.append('file_id', fileId);

        // Server.post('/removeCaseFile', formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        // })
        Server.delete('/removeCaseFile/', {
            params: { file_id: obj.id },
            headers: {
                'Content-Type': 'application/json', // Override to a more appropriate Content-Type
            },
        })
            .then((response) => {
                if (response.data.meta.status === 200 && !response.data.meta.is_error) {
                    onDeleteImage(obj);
                    showSnackbar(response.data.meta.message, 'success');
                } else {
                    showSnackbar(response.data.meta.message, 'error');
                }
            })
            .catch((error) => {
                showSnackbar('File removal failed', 'error');
            });
    };

    return (
        <>
            {isShown && (
                <div className="input-group" style={{ zIndex: 0 }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Image name"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        value={employeeDocsData?.file_name}
                        readOnly
                    />
                    <button type="button" className="btn btn-outline-primary">
                        <VisibilityIcon onClick={() => handleOpenDialog()} />
                    </button>
                    {employeeDocsData?.id && (
                        <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={() => handleDeleteImage(employeeDocsData)}
                        >
                            <DeleteIcon />
                        </button>
                    )}
                </div>
            )}
            <ImageDailog
                isOpen={isDialogOpen}
                handleClose={handleCloseDialog}
                obj={employeeDocsData}
            />

            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                type={snackbarType}
                onClose={handleCloseSnackbar}
            />
        </>
    );
};

export default ImageItem;

import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    Button,
    Grid,
    IconButton,
} from '@mui/material';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface CheckEmployeeValidDailogProps {
    isOpen: boolean;
    handleClose: () => void;
    handleEmployeeFormSubmit: (formVal: any) => void;
    uidVal: string;
}

const CheckEmployeeSchema = Yup.object().shape({
    mobile_no: Yup.string()
        .required('Mobile No. is required')
        .min(10, 'Mobile No. must be at least 10 characters'),
    case_uid: Yup.string(),
});

const CheckEmployeeValidDailog: React.FC<CheckEmployeeValidDailogProps> = ({
    isOpen,
    handleClose,
    handleEmployeeFormSubmit,
    uidVal,
}) => {
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            mobile_no: '',
            case_uid: uidVal || '',
        },
        validationSchema: CheckEmployeeSchema,
        onSubmit: (values) => {
            handleEmployeeFormSubmit(values);
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
        formik;

    React.useEffect(() => {
        if (uidVal) {
            setFieldValue('case_uid', uidVal);
        }
    }, [uidVal, setFieldValue]);

    const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {};

    return (
        <Dialog
            open={isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose();
                }
            }}
            aria-labelledby="verify-mobile-dialog"
            sx={{
                '& .MuiDialog-paper': {
                    height: '15rem',
                },
            }}
        >
            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column',
                }}
            >
                <FormikProvider value={formik}>
                    <Form
                        className="w-100 h-100"
                        autoComplete="off"
                        noValidate
                        onSubmit={formik.handleSubmit}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                maxWidth: '100%',
                                margin: 'auto',
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '1.25rem',
                                        textAlign: 'center',
                                        margin: '0 0 1.25rem',
                                    }}
                                >
                                    Employee Details
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete="mobile_no"
                                        type="number"
                                        label="Mobile"
                                        {...getFieldProps('mobile_no')}
                                        error={Boolean(touched.mobile_no && errors.mobile_no)}
                                        helperText={touched.mobile_no && errors.mobile_no}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        sx={{ display: 'none' }}
                                        fullWidth
                                        autoComplete="case_uid"
                                        label="Uid"
                                        {...getFieldProps('case_uid')} // Handles value and onChange
                                        error={Boolean(touched.case_uid && errors.case_uid)}
                                        helperText={
                                            touched.case_uid && typeof errors.case_uid === 'string'
                                                ? errors.case_uid
                                                : undefined
                                        }
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                        marginTop: '1.25rem',
                                    }}
                                >
                                    <Button
                                        sx={{
                                            fontSize: '0.875rem',
                                            padding: '0.625rem 1.25rem',
                                            fontWeight: 'bold',
                                        }}
                                        variant="contained"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </FormikProvider>
            </DialogContent>
        </Dialog>
    );
};

export default CheckEmployeeValidDailog;

// CustomFileUpload.tsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import Server from '../api/index';
import CustomSnackbar from '../components/CustomSnackbar';

const Input = styled('input')({
    display: 'none',
});

const FileUploadBox = styled(Box)<{ height: string; width: string }>(({ height, width }) => ({
    width,
    height,
    border: '2px dashed #ccc',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    position: 'relative',
}));

const ContactImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const ClearButton = styled(IconButton)({
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'transparent',
    color: 'red',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    height: '1rem',
    width: '1rem',
});

// CustomFileUpload.tsx
interface CustomFileUploadProps {
    id: string;
    height: string;
    width: string;
    title?: string;
    error?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    existingFile?: string;
    vid?: string; // New prop for vid
    fileType?: string; // New prop for fileType
    fileId?: string; // New prop for fileId for removal
}

const CustomFileUpload: React.FC<CustomFileUploadProps> = ({
    id,
    height,
    width,
    title,
    error,
    onChange,
    existingFile,
    vid,
    fileType,
    fileId,
}) => {
    const [imagePreview, setImagePreview] = useState<string | null>(existingFile || null);

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarType, setSnackbarType] = React.useState<'success' | 'error'>('success');

    useEffect(() => {
        setImagePreview(existingFile || null);
    }, [existingFile]);

    const showSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarType(type);
        setSnackbarOpen(true);
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    setImagePreview(reader.result.toString());
                }
            };
            reader.readAsDataURL(file);
            console.log('file>>>>', file);

            const formData = new FormData();
            formData.append('vid', vid || '2'); // Use passed vid or default to '2'
            formData.append('fileType', fileType || 'verifier_photo_id'); // Use passed fileType or default
            formData.append('upload_file', file);

            Server.post('/uploadCaseFiles', formData)
                .then((response) => {
                    if (response.data.meta.status === 200 && !response.data.meta.is_error) {
                        const { data } = response.data;
                        const newUrl = data.url;
                        if (onChange) {
                            onChange(data);
                        }
                        setImagePreview(newUrl);
                        showSnackbar(response.data.meta.message, 'success');
                    } else {
                        showSnackbar(response.data.meta.message, 'error');
                    }
                })
                .catch((error) => {
                    showSnackbar('File upload failed', 'error');
                });
        }
    };

    const handleClearImage = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setImagePreview(null);
        event.stopPropagation();
    };

    return (
        <>
            <Box>
                <Input
                    accept="image/*"
                    id={`file-upload-${id}`}
                    type="file"
                    onChange={handleFileChange}
                    capture
                />
                <FileUploadBox
                    height={height}
                    width={width}
                    onClick={() => document.getElementById(`file-upload-${id}`)?.click()}
                >
                    {imagePreview ? (
                        <>
                            <ContactImage src={imagePreview} alt="Preview" />
                            <ClearButton onClick={handleClearImage}>
                                <ClearIcon
                                    sx={{ height: '1rem', width: '1rem', fontWeight: 'bold' }}
                                />
                            </ClearButton>
                        </>
                    ) : (
                        <>
                            <AssignmentIndIcon style={{ fontSize: 25, marginBottom: 10 }} />
                            <Typography variant="body1">
                                {title ? title : 'Select Photo'}
                            </Typography>
                        </>
                    )}
                </FileUploadBox>
                {error && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {error}
                    </Typography>
                )}
            </Box>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                type={snackbarType}
                onClose={handleCloseSnackbar}
            />
        </>
    );
};

export default CustomFileUpload;
